import React, { useState } from "react";
import { useEffect } from "react";
import CseResultsAfterStripping from "./CseResults";
import useCse from "../../hooks/useCse";
import useCseFirstdTurn from "../../hooks/useCseFirstRound";

const CseResultsDisplay = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const cseId = searchParams.get("cseId");
  const match = {
    params: {
      cseId: cseId,
    },
  };
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log("LOADED :", loaded);
  }, []);
  const cse = useCse(cseId as any);
  
  return (
    <React.StrictMode>
      <div style={{ zoom: 0.15 }}>
        <>
          <div
            style={{
              fontSize: "35px",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            Procès-Verbal de l'élection {cse?.cseName} {cse?.cseParentId ? "- 2nd tour" : ""}
          </div>
          <CseResultsAfterStripping
            match={match}
            printing
            setLoaded={setLoaded}
          />
        </>
      </div>
    </React.StrictMode>
  );
};

export default CseResultsDisplay;
